import * as React from 'react'
import {  Link } from 'gatsby'
import { useState } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import * as navHeaderStyle from '../styles/navHeader.module.scss'



const NavHeaderTitle = ({ children }) => {

  const [toggleNav, setToggleNav]=useState(false)

  const [open, setOpen] = useState(false);

  const mobileNavButton = open ? "toCloseTitle" : "toOpenTitle";

  const showMenu=()=> setToggleNav(!toggleNav);

    return (
      <header className={navHeaderStyle.home}>
          <div className={navHeaderStyle.headerContainer} id={navHeaderStyle.homeHeader}>
          <nav>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/spravy">Správy</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/eGovernment">eGovernment</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/projekty">Projekty</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti">O spoločnosti</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti/#contact">Kontakt</Link>
                    <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en">EN</Link>
              </nav>
              <div id={navHeaderStyle.mobileNavButtonTitleId} className={mobileNavButton} onClick={() => {setOpen(!open);showMenu()}}></div>
              
              
              </div>
              {toggleNav && <div className={navHeaderStyle.mobileNavContainer} id={navHeaderStyle.ttNav}>
                        <nav className={navHeaderStyle.mobileNav} id={navHeaderStyle.ttNavContent}>
                        <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/spravy">Správy</Link>
                        <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/eGovernment">eGovernment</Link>
                        <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/projekty">Projekty</Link>
                        <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti">O spoločnosti</Link>
                        <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/o-spolocnosti/#contact">Kontakt</Link>
                        <Link activeClassName={navHeaderStyle.active} partiallyActive={true} to="/en">EN</Link>
                        </nav>
                </div>}
      </header>
    )
  }
  
  export default NavHeaderTitle