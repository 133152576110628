// extracted by mini-css-extract-plugin
export var homePageContainerParent = "homePage-module--homePageContainerParent--1BC87";
export var homePageContainer = "homePage-module--homePageContainer--1RhWN";
export var hpCover = "homePage-module--hpCover--3yKES";
export var homeLogoContainer = "homePage-module--homeLogoContainer--2ljey";
export var titleContainer = "homePage-module--titleContainer--2U-AM";
export var title = "homePage-module--title--3WKhR";
export var homeContent = "homePage-module--homeContent--1tQmZ";
export var upperContentContainer = "homePage-module--upperContentContainer--xBDI1";
export var lowerfelxElement = "homePage-module--lowerfelxElement--JF9va";
export var upperFlexElement = "homePage-module--upperFlexElement--1F7o1";
export var lowerNewsContainer = "homePage-module--lowerNewsContainer--3BOB4";
export var titleNewsContainerContent = "homePage-module--titleNewsContainerContent--3Yxmw";
export var titleNewsWrapper = "homePage-module--titleNewsWrapper--EZ_wV";
export var titleNewsSectionContainer = "homePage-module--titleNewsSectionContainer--1KBm5";
export var heading = "homePage-module--heading--3J5XE";
export var description = "homePage-module--description--1v3c1";
export var moreButton = "homePage-module--moreButton--eWjjW";
export var homeH = "homePage-module--homeH--l-7yn";