import * as React from 'react'
import NavHeaderTitle from '../components/navHeaderTitle'
import * as homeStyle from '../styles/homePage.module.scss'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'

const IndexPage = ({data}) => {
  const ExtractDataTitle = data.allMdx.nodes
  return (
    <main>
    <NavHeaderTitle />
    <div className={homeStyle.homePageContainerParent}>
    <div className={homeStyle.homePageContainer}>
        <div className={homeStyle.hpCover}>
            <div className={homeStyle.homeContent}>
                <div className={homeStyle.upperContentContainer}>
               
                    <div className={homeStyle.titleContainer}>
                        <div className={homeStyle.homeLogoContainer}></div>
                        <div className={homeStyle.title}>
                            <h1 className={homeStyle.homeH}>eGovernment • Informačné systémy a komunikačná infraštrukúra</h1>
                        </div>
                    </div>
                </div>
                <div className={homeStyle.lowerNewsContainer}>
                <Link to="/spravy" className={homeStyle.moreButton}>Viac správ</Link>
                    <div className={homeStyle.titleNewsWrapper}>
                    
                        <div className={homeStyle.titleNewsContainerContent}>

                            {
                            ExtractDataTitle.map(node=> (

                            <Link to={"/" + node.slug} key={node.id}>
                            <div className={homeStyle.titleNewsSectionContainer}>
                                <h2 className={homeStyle.heading}>{node.frontmatter.title}</h2>
                                <p className={homeStyle.description}>{node.frontmatter.preview}</p>
                            </div>
                            </Link>
                            ))
                            }

                        </div>
                        
                    </div>

                </div>
            </div>

        </div>
    </div>

    </div>
</main>
  )
}

export const query = graphql`
{
  allMdx(
    filter: {frontmatter: {categories: {eq: "sk"}}}
    limit: 3
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    nodes {
      frontmatter {
        title
        categories
        date
        preview
      }
      id
      slug
    }
  }
}
`

export default IndexPage

